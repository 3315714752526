import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useState } from 'react';
import {
    Space,
    Button,
    Tag,
    Typography,
    Flex,
    Table,
    Modal,
    message,
    Checkbox,
    Tree,
    Tooltip,
    Empty,
} from 'antd';
import { DeleteOutlined, EditOutlined, MobileOutlined, FolderOpenFilled } from '@ant-design/icons';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { ResourceRole } from 'apis/UserApi';
import { useOutdoorState } from 'providers/OutdoorProvider';
import LandmarkInputDialog from 'components/dialogs/LandmarkInputDialog';
import { deleteLandmark, getLandmarkSurveys, LmSurveyDto } from 'apis/LandmarkApi';
import moment from 'moment';
import CustomIcon from 'components/CustomIcon';
const { Text, Title } = Typography;

const LandmarkSurveySection: React.FC = () => {
    const { workingLandmark } = useOutdoorState();
    const [lmSurveys, setLmSurveys] = useState<LmSurveyDto[]>([]);
    const [checkedRow, setCheckedRow] = useState<React.Key[]>([]);

    useEffect(() => {
        if (workingLandmark?.id && (workingLandmark.survey ?? 0) > 0) {
            getLandmarkSurveys(workingLandmark.id).then((resp) => {
                const svData = resp.data ?? [];
                setLmSurveys(svData);
                setCheckedRow(svData.filter((el) => el.enabled).map((x) => x.id));
            });
        } else {
            setLmSurveys([]);
        }
    }, [workingLandmark]);

    const treeData = useMemo(() => {
        return lmSurveys.map((el) => {
            const deviceInfo = (
                <Space>
                    <MobileOutlined />
                    <span>
                        {el.deviceModel} ({el.deviceOs})
                    </span>
                </Space>
            );
            return {
                title: (
                    <Tooltip
                        title={deviceInfo}
                        color="grey"
                        placement="right"
                        trigger="contextMenu"
                    >
                        <Flex className="tree-title" justify="space-between" align="center">
                            <Space title={el.id} style={{ flex: 1 }}>
                                <FolderOpenFilled style={{ color: '#4164fb' }} />
                                <Typography.Text className="line-clamp">
                                    {moment(el.recordAt).format('YYYY-MM-DD HH:mm Z')}
                                </Typography.Text>
                            </Space>
                            <Typography.Text type="secondary">{`-`}</Typography.Text>
                        </Flex>
                    </Tooltip>
                ),
                key: el.id,
                disabled: true,
            };
        });
    }, [lmSurveys]);

    const checkedPaths = checkedRow.length;
    const totalPaths = lmSurveys.length;
    if (totalPaths === 0) {
        return (
            <Space direction="vertical" className="w100">
                <Title level={5}>Survey</Title>
                <Empty description="No survey available." />
            </Space>
        );
    }
    return (
        <ProCard
            title={
                <Space style={{ marginLeft: 12 }}>
                    <Checkbox
                        indeterminate={checkedPaths !== totalPaths && checkedPaths > 0}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setCheckedRow(lmSurveys.map((x) => x.id));
                            } else {
                                setCheckedRow([]);
                            }
                        }}
                        checked={checkedPaths === totalPaths && totalPaths > 0}
                    />
                    <Typography.Text>Survey</Typography.Text>
                </Space>
            }
            subTitle={`${checkedPaths}/${totalPaths}`}
            headerBordered
            ghost
            size="small"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
            bodyStyle={{ padding: 0 }}
        >
            <Tree
                checkable
                showIcon
                blockNode
                onCheck={(checkedKeys, info) => {
                    setCheckedRow(checkedKeys as React.Key[]);
                }}
                checkedKeys={checkedRow}
                treeData={treeData}
                rootClassName="survey-tree"
                rootStyle={{
                    backgroundColor: 'transparent',
                    padding: '6px 6px 6px 0',
                }}
            />
        </ProCard>
    );
};

const LandmarkDetailSection: React.FC = () => {
    const { workingLandmark, availableClusters, fetchLandmarks, selectLandmark } =
        useOutdoorState();
    const { hasAccess } = useAuth();
    const { activeProject } = useAppState();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, messageCtxHolder] = message.useMessage();

    const _handleDelete = () => {
        if (!workingLandmark) return;
        modal
            .confirm({
                title: 'Delete landmark',
                content: (
                    <span>
                        Are you sure to delete <b>{workingLandmark.name}</b>?
                    </span>
                ),
                okText: 'Delete',
                okType: 'danger',
            })
            .then(
                (confirmed) => {
                    if (confirmed) {
                        deleteLandmark(workingLandmark.id)
                            .then((resp) => {
                                selectLandmark(undefined);
                                fetchLandmarks();
                                messageApi.info('Landmark removed');
                            })
                            .catch(() => {});
                    }
                },
                () => {},
            );
    };

    const copyableRow = (text: string) => (
        <Flex align="center">
            <span style={{ flex: 1 }}>{text}</span>
            <Typography.Text copyable={{ text }} />
        </Flex>
    );
    const dataSource = useMemo(() => {
        if (!workingLandmark) return [];
        const mRows = [
            ['ID', copyableRow(workingLandmark.id)],
            ['Address', workingLandmark.address ?? '-'],
            [
                'Coordinate',
                copyableRow(`${workingLandmark.position.lat},${workingLandmark.position.lng}`),
            ],
            ['Radius', workingLandmark.radius + ' m'],
            [
                'Clusters',
                <Flex wrap="wrap">
                    {(workingLandmark.clusters ?? []).map((c, i) => {
                        return <Tag key={`clusters-${i}-${c}`}>{c}</Tag>;
                    })}
                </Flex>,
            ],
            [
                'Level',
                <Space>
                    <span>{workingLandmark.level}</span>
                    {workingLandmark.isIndoor ? (
                        <Tag color="cyan" bordered={false}>
                            Indoor
                        </Tag>
                    ) : undefined}
                </Space>,
            ],
        ];
        if (workingLandmark.extId) {
            mRows.splice(1, 0, ['Ext ID', copyableRow(workingLandmark.extId)]);
        }
        return mRows;
    }, [workingLandmark]);

    const cardPadding = useMemo(() => ({ padding: 12, margin: 0 }), []);
    if (!workingLandmark) return <></>;
    const _renderActionSection = () => {
        return (
            <Space style={cardPadding}>
                <Button danger icon={<DeleteOutlined />} size="small" onClick={_handleDelete}>
                    Delete
                </Button>
                {isEditor ? (
                    <LandmarkInputDialog
                        key="btn-edit-lm"
                        venueId={workingLandmark.venueId}
                        record={workingLandmark}
                        availableClusters={availableClusters}
                        trigger={
                            <Button icon={<EditOutlined />} size="small">
                                Edit
                            </Button>
                        }
                        onSuccess={() => {
                            fetchLandmarks();
                            messageApi.success('Landmark updated');
                        }}
                    />
                ) : undefined}
            </Space>
        );
    };

    return (
        <ProCard
            colSpan="30%"
            style={{ overflowY: 'auto' }}
            bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            bordered
        >
            {contextHolder}
            {messageCtxHolder}
            <Title style={{ ...cardPadding, backgroundColor: '#e6f4ffaa' }} level={5}>
                {workingLandmark.name}
            </Title>
            <Table
                columns={[
                    {
                        title: 'k',
                        width: 100,
                        render: (val, record) => <Text type="secondary">{record[0]}</Text>,
                    },
                    { title: 'v', render: (val, record) => record[1] },
                ]}
                rowKey={(record) => `conf-key-${record[0]}`}
                dataSource={dataSource}
                pagination={false}
                showHeader={false}
                size="small"
            />
            {_renderActionSection()}
            <div style={{ padding: '6px 12px' }}>
                <LandmarkSurveySection />
            </div>
        </ProCard>
    );
};

export default LandmarkDetailSection;
