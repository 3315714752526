import React, { createContext, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useAppState } from './AppStateProvider';
import { VenueInfoDto } from 'apis/VenueApi';
import { getLandmarkList, LandmarkDto } from 'apis/LandmarkApi';
import { PaginationDto, PaginationQueryDto } from 'apis/TraceApi';

export type OutdoorContextType = {
    isLoading?: boolean;
    switchVenue: (venueId: string, mapId?: string | null) => void;
    venue?: VenueInfoDto;
    fetchLandmarks: (filter?: any) => void;
    landmarks?: LandmarkDto[];
    availableClusters: string[];
    selectLandmark: (landmarkId?: string | LandmarkDto) => void;
    workingLandmark?: LandmarkDto;
    lmPageConf: React.MutableRefObject<any>;
};
const OutdoorContext = createContext<OutdoorContextType>({} as OutdoorContextType);
const OutdoorContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [venue, setVenue] = useState<VenueInfoDto>();
    const [landmarks, setLandmarks] = useState<LandmarkDto[]>();
    const lmPageConf = useRef<PaginationDto | PaginationQueryDto>();
    const [workingLmId, setWorkingLmId] = useState<string | undefined>();
    const { project } = useAppState();

    useEffect(() => {
        setLandmarks([]);
        setWorkingLmId(undefined);
        lmPageConf.current = undefined;
        if (!!venue && !!project) {
            fetchLandmarks();
        }
    }, [project, venue]);

    const fetchLandmarks = (filter?: any) => {
        const { page = 0, limit = 20 } = filter || lmPageConf.current || {};
        const mVenueId = filter?.venueId || venue?.id;
        if (!mVenueId) return;
        setIsLoading(true);
        getLandmarkList(mVenueId, page, limit)
            .then((resp) => {
                const mPageConf = resp.response?.data?._metadata?.pagination;
                if (mPageConf) lmPageConf.current = { page, limit, ...mPageConf };
                setLandmarks(resp.data);
            })
            .catch((ex) => {
                setLandmarks([]);
                lmPageConf.current = { page, limit, total: 0, totalPage: 0 };
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    function switchVenue(venueId: string, lmId?: string | null) {
        const v = (project?.venues ?? []).find((el) => el.id === venueId);
        setVenue(v);
    }

    const selectLandmark = (landmarkId?: string | LandmarkDto) => {
        if (!landmarkId) {
            setWorkingLmId(undefined);
        } else {
            setWorkingLmId(typeof landmarkId === 'string' ? landmarkId : landmarkId?.id);
        }
    };

    const workingLandmark = useMemo(() => {
        return (landmarks ?? []).find((el) => el.id === workingLmId);
    }, [landmarks, workingLmId]);

    const availableClusters = useMemo(() => {
        return Array.from(new Set((landmarks ?? []).map((el) => el.clusters ?? []).flat()));
    }, [landmarks]);

    return (
        <OutdoorContext.Provider
            value={{
                isLoading,
                switchVenue,
                venue,
                fetchLandmarks,
                landmarks,
                availableClusters,
                selectLandmark,
                workingLandmark,
                lmPageConf,
            }}
        >
            {children}
        </OutdoorContext.Provider>
    );
};
export default OutdoorContextProvider;
export const useOutdoorState = () => {
    const ctx = useContext(OutdoorContext);
    if (!ctx) {
        throw new Error('useVenue has to be used within <OutdoorContextProvider>');
    }
    return ctx;
};
