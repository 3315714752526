import ApiClient from 'utils/NetworkManager';
import { LatLngDto } from './VenueApi';

export interface LandmarkDto {
    id: string;
    extId?: string;
    venueId: string;
    name: string;
    address?: string;
    position: LatLngDto;
    radius: number;
    clusters?: string[];
    level: number;
    isIndoor: boolean;
    survey?: number;
}

export interface LmSurveyEnableDto {
    id: string;
    enabled: boolean;
}
export interface LmSurveyDto extends LmSurveyEnableDto {
    deviceOs?: string;
    deviceModel?: string;
    recordAt: Date;
    sample?: { wifi: number; ble: number; mov: number };
}


export const getLandmarkList = (id: string, page: number = 0, limit: number = 20) => ApiClient.get<LandmarkDto[]>('/landmark/list', {id, page, limit});
export const createLandmark = (data: Omit<LandmarkDto, 'id'>) => ApiClient.post<LandmarkDto>('/landmark', data);
export const updateLandmark = (id: string, data: LandmarkDto) => ApiClient.put(`/landmark/${id}`, data);
export const deleteLandmark = (id: string) => ApiClient.delete(`/landmark/${id}`);
export const getLandmarkSurveys = (id: string) => ApiClient.get<LmSurveyDto[]>(`/landmark/${id}/survey`);
export const enableLmSurvey = (id: string, data: LmSurveyEnableDto) => ApiClient.put<LmSurveyDto>(`/landmark/${id}/survey`, data);