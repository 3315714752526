import {
    ModalForm,
    ProForm,
    ProFormText,
    ProFormSwitch,
    ProFormTextArea,
    ProFormDigit,
    ProFormList,
    ProFormGroup,
    ProFormSelect,
} from '@ant-design/pro-components';
import { Form, Typography, Modal, message, Upload, Space, Button, Flex, Alert, Select } from 'antd';
import React, { useRef, useState } from 'react';
import { LoadingOutlined, FileZipOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import {
    VenueCreateDto,
    VenueDto,
    VenueCloneDto,
    VenuePackageCreateDto,
    VenuePackagePublishDto,
    VenueBoundConfigDto,
    createVenue,
    deleteVenue,
    updateVenue,
    cloneVenue,
    createVenueRuntimeConfig,
    updateVenueRuntimeConfig,
    deleteVenueRuntimeConfig,
    createVenuePackage,
    publishVenuePackage,
    updateVenueActiveBoundConfig,
    VenueRuntimeConfigDto,
    VenueRuntimeConfigEditDto,
} from 'apis/VenueApi';
import type { UploadProps } from 'antd';
import { BaseDialogProps } from './BaseDialogProps.type';
const { Text, Paragraph } = Typography;
import NetworkManager from 'utils/NetworkManager';
import LocationPicker from 'components/maps/LocationPicker';
import { useAuth } from 'providers/AuthProvider';
import { ResourceEntry, ResourceRole } from 'apis/UserApi';
import FormItem from 'antd/es/form/FormItem';

export type AddVenueProp = BaseDialogProps & {
    projectId: string;
    isEdit: boolean;
    editingVenue: any;
};
export const AddProjVenueModule: React.FC<AddVenueProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const mapRef = useRef<any>();
    const [venueForm] = Form.useForm();
    const isEdit = props.isEdit;
    const editingVenue = props.editingVenue;

    const _handleSubmit = async (values: any) => {
        setFormLoading(true);
        let result = false;

        const finalCenter = { lat: values.latitude, lng: values.longitude };
        if (!isEdit) {
            const mData: VenueCreateDto = {
                projectId: props.projectId,
                name: values.name,
                center: finalCenter,
                indoor: values.indoor,
            };
            try {
                const resp = await createVenue(mData);
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    if (props.onSuccess) props.onSuccess();
                    result = true;
                }
            } catch (ex: any) {}
        } else {
            const mData: VenueDto = {
                projectId: props.projectId,
                name: values.name,
                center: finalCenter,
                enabled: values.enabled,
                id: editingVenue.id,
                indoor: values.indoor,
            };

            try {
                const resp = await updateVenue(editingVenue.id, mData);
                if (resp.response?.status === 200 || resp.response?.status === 201) {
                    if (props.onSuccess) props.onSuccess();
                    result = true;
                }
            } catch (ex: any) {}
        }

        setFormLoading(false);
        return result;
    };

    return (
        <ModalForm
            form={venueForm}
            title={isEdit ? 'Edit venue' : 'Add venue'}
            trigger={props.trigger}
            initialValues={
                isEdit
                    ? {
                          name: editingVenue.name,
                          latitude: editingVenue.center.lat,
                          longitude: editingVenue.center.lng,
                          enabled: editingVenue.enabled,
                          indoor: editingVenue.indoor ?? true,
                      }
                    : undefined
            }
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    venueForm.resetFields();
                }
            }}
            onFinish={_handleSubmit}
        >
            {isEdit ? (
                <Paragraph type="secondary">
                    <span>ID: </span>
                    <Text copyable>{editingVenue.id}</Text>
                </Paragraph>
            ) : undefined}

            <ProFormText
                name="name"
                label="Name"
                placeholder="Venue name"
                rules={[{ required: true }]}
            />
            <ProForm.Item label="Center" required>
                <Space.Compact>
                    <ProFormDigit
                        name="latitude"
                        placeholder="Latitude"
                        rules={[{ required: true }]}
                        fieldProps={{
                            onPaste: (evt) => {
                                const pastedValue = evt.clipboardData.getData('text');
                                const parts = pastedValue.split(',');
                                if (parts.length === 2) {
                                    venueForm.setFieldsValue({
                                        latitude: Number(parts[0]),
                                        longitude: Number(parts[1]),
                                    });
                                    evt.preventDefault();
                                }
                            },
                        }}
                    />
                    <ProFormDigit
                        name="longitude"
                        placeholder="Longitude"
                        rules={[{ required: true }]}
                    />
                </Space.Compact>
                <Button
                    type="text"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        mapRef.current.flyTo({
                            lat: venueForm.getFieldValue('latitude'),
                            lng: venueForm.getFieldValue('longitude'),
                        });
                    }}
                />
                <LocationPicker
                    ref={mapRef}
                    style={{ width: '100%', height: 200 }}
                    center={isEdit ? editingVenue.center : undefined}
                    onChange={(c) => {
                        venueForm.setFieldsValue({
                            latitude: c.lat,
                            longitude: c.lng,
                        });
                    }}
                />
            </ProForm.Item>
            <ProFormSwitch
                name="enabled"
                label="Enable"
                initialValue={editingVenue.enabled ?? true}
                hidden={!isEdit}
            />
            <ProFormSwitch
                name="indoor"
                label="Indoor venue"
                initialValue={editingVenue.indoor ?? true}
            />
        </ModalForm>
    );
};

export type DeleteVenueProp = BaseDialogProps & {
    processingVenue: any;
};

export const DeleteProjVenueModule: React.FC<DeleteVenueProp> = (props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const confirmDelete = async () => {
        setConfirmLoading(true);

        try {
            const resp = await deleteVenue(props.processingVenue.id);
            if (resp.response?.status === 200 || resp.response?.status === 201) {
                if (props.onSuccess) props.onSuccess();
            }
        } catch (ex: any) {}

        setOpen(false);
        setConfirmLoading(false);
    };

    const cancelDelete = () => {
        setOpen(false);
    };
    return (
        <>
            {props.trigger ? <div onClick={() => setOpen(true)}>{props.trigger}</div> : undefined}
            <Modal
                title={'Delete ' + props.processingVenue.name}
                open={open}
                onOk={confirmDelete}
                okButtonProps={{ danger: true }}
                confirmLoading={confirmLoading}
                onCancel={cancelDelete}
            >
                <p>
                    Are you sure to delete <b>{props.processingVenue.name}</b>?
                </p>
            </Modal>
        </>
    );
};

export type CloneProjVenueProp = BaseDialogProps & {
    processingProjectId: string;
    cloningVenue: any;
};
export const CloneProjVenueModule: React.FC<CloneProjVenueProp> = (props) => {
    const { user, hasAccess } = useAuth();
    const [formLoading, setFormLoading] = useState<boolean>();
    const [cloneVenueForm] = Form.useForm();
    const [selectableProject, setSelectableProject] = useState<ResourceEntry[]>();
    const [selectedProjId, setSelectedProjId] = useState<string>();

    return (
        <ModalForm
            form={cloneVenueForm}
            title={'Copy Venue - ' + props.cloningVenue.name}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                setSelectedProjId(undefined);
                if (isOpen) {
                    const myProjects = user?.resources ?? [];
                    const availableProj = myProjects.filter((el) => {
                        return (
                            el.projId !== props.processingProjectId &&
                            hasAccess(ResourceRole.EDITOR, el.projId)
                        );
                    });
                    setSelectableProject(availableProj);
                } else {
                    setSelectableProject(undefined);
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);

                if (!selectedProjId) {
                    message.error('Please select a project');
                    return;
                }

                const mData: VenueCloneDto = {
                    projectId: selectedProjId,
                };
                let result = false;
                try {
                    const resp = await cloneVenue(props.cloningVenue.id, mData);
                    if (resp.response?.status === 200 || resp.response?.status === 201) {
                        if (props.onSuccess) props.onSuccess();
                        result = true;
                        setFormLoading(false);
                        return result;
                    }
                } catch (ex) {
                    message.error('error');
                    setFormLoading(false);
                    return result;
                }
            }}
        >
            <Text>Select a destination project</Text>
            <Select
                loading={!user?.resources}
                placeholder="Select a destination project."
                style={{ width: '100%', margin: '24px 0' }}
                options={(selectableProject ?? []).map((el) => ({
                    value: el.projId,
                    label: el.name ?? el.projId,
                }))}
                onChange={(value, option) => {
                    setSelectedProjId(value);
                }}
            />
        </ModalForm>
    );
};

export type AddVenueFingerprintProp = BaseDialogProps & {
    venueId: string;
};
export const AddVenueFingerprintModule: React.FC<AddVenueFingerprintProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [venueFingerprintForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [pendingZipInfo, setPendingZipInfo] = useState<any>(undefined);

    const _renderUploadButton = (icon: any, text: string) => (
        <Button>
            <Space>
                {icon}
                <Text>{text}</Text>
            </Space>
        </Button>
    );

    const beforeUpload = () => {
        return false;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        // setImageUrl(undefined);
        setIsLoading(true);
        // //showPreview(info);

        if (info.file.type != 'application/zip') {
            setIsLoading(false);
            message.error('Only ZIP file is accepted');
            return;
        }

        if (info.fileList[info.fileList.length - 1].originFileObj) {
            setIsLoading(false);
            setPendingZipInfo(info.fileList[info.fileList.length - 1]);
        }
    };

    return (
        <ModalForm
            form={venueFingerprintForm}
            title={'Upload site package'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    venueFingerprintForm.resetFields();
                    setPendingZipInfo(undefined);
                },
            }}
            onOpenChange={(isOpen) => {}}
            onFinish={async (values) => {
                if (!pendingZipInfo) {
                    message.error('Please select a ZIP file');
                    return;
                }

                setFormLoading(true);
                let result = false;

                const mData: VenuePackageCreateDto = {
                    venueId: props.venueId,
                    desc: values.desc,
                    fileName: pendingZipInfo.name,
                };

                try {
                    const resp = await createVenuePackage(mData);
                    if (resp.response?.status === 200 || resp.response?.status === 201) {
                        result = true;
                        if (resp.response.data.data && resp.response.data.data.uploadUrl) {
                            try {
                                message.info('Uploading ZIP file');
                                await NetworkManager.putFile(
                                    resp.response.data.data.uploadUrl,
                                    pendingZipInfo.originFileObj as File,
                                );
                                if (props.onSuccess) props.onSuccess();
                                setFormLoading(false);
                                return result;
                            } catch (ex: any) {
                                message.error('upload file error');
                                setFormLoading(false);
                            }
                        } else {
                            message.error('error');
                            setFormLoading(false);
                        }
                    }
                } catch (ex: any) {
                    message.error('error');
                    setFormLoading(false);
                }
            }}
        >
            <ProFormTextArea
                name="desc"
                label="Description"
                placeholder="Enter a short commit message."
                rules={[{ required: true }]}
            />

            <Form.Item label="Site package (*.zip)" required>
                <Upload
                    name="sp-file"
                    accept=".zip,zip,application/zip"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    fileList={[]}
                    onChange={handleChange}
                >
                    {isLoading
                        ? _renderUploadButton(<LoadingOutlined />, 'Loading')
                        : pendingZipInfo
                          ? _renderUploadButton(<FileZipOutlined />, pendingZipInfo.name)
                          : _renderUploadButton(<UploadOutlined />, 'Click to upload')}
                </Upload>
            </Form.Item>
            <br />
        </ModalForm>
    );
};

export type PublishVenueFingerprintProp = BaseDialogProps & {
    fingerprintId: string;
};
export const PublishVenueFingerprintModule: React.FC<PublishVenueFingerprintProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [publishVenueFingerprintForm] = Form.useForm();
    const fingerprintId = props.fingerprintId;

    return (
        <ModalForm
            form={publishVenueFingerprintForm}
            title={'Publish site package'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: 'Publish',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    publishVenueFingerprintForm.resetFields();
                },
            }}
            onOpenChange={(isOpen) => {}}
            onFinish={async (values) => {
                setFormLoading(true);

                const mData: VenuePackagePublishDto = {
                    id: fingerprintId,
                    desc: values.desc,
                };
                try {
                    const resp = await publishVenuePackage(mData);
                    if (resp.response?.status === 200 || resp.response?.status === 201) {
                        if (props.onSuccess) props.onSuccess();
                        setFormLoading(false);
                        return true;
                    }
                } catch (ex: any) {
                    message.error('error');
                    setFormLoading(false);
                }
            }}
        >
            <ProFormTextArea
                name="desc"
                label="Release note"
                placeholder="Enter a short release note (max 500 characters)."
                fieldProps={{ showCount: true, maxLength: 500 }}
                rules={[
                    { required: true },
                    {
                        type: 'string',
                        max: 500,
                        message: 'release note cannot longer than 500 char',
                    },
                ]}
            />
        </ModalForm>
    );
};

export type AddVenueRuntimeConfigProp = BaseDialogProps & {
    venueId: string;
    processingConfig: any;
    isEdit: boolean;
};
export const AddVenueRuntimeConfigModule: React.FC<AddVenueRuntimeConfigProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [venueRuntimeConfigForm] = Form.useForm();

    const [selectingOS, setSelectingOS] = useState<string>('ANY');

    return (
        <ModalForm
            form={venueRuntimeConfigForm}
            title={props.isEdit ? 'Edit venue runtime config' : 'Add venue runtime config'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: props.isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    venueRuntimeConfigForm.resetFields();
                },
            }}
            onOpenChange={(isOpen) => {
                if (isOpen && props.processingConfig) {
                    setSelectingOS(props.processingConfig.rules.os);
                    venueRuntimeConfigForm.setFieldsValue({
                        brand: props.processingConfig.rules.brand,
                        osVerFrom: props.processingConfig.rules.osVerFrom,
                        osVerTo: props.processingConfig.rules.osVerTo,
                        sdkVerFrom: props.processingConfig.rules.sdkVerFrom,
                        sdkVerTo: props.processingConfig.rules.sdkVerTo,
                        desc: props.processingConfig.desc,
                        content: JSON.stringify(
                            JSON.parse(props.processingConfig.content),
                            null,
                            3,
                        ),
                        priority: props.processingConfig.priority,
                        enabled: props.processingConfig.enabled,
                    });
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);

                let contentJsonString = '';
                try {
                    contentJsonString = JSON.stringify(JSON.parse(values.content));
                } catch (error) {
                    message.error('Content json format invalid');
                    setFormLoading(false);
                    return;
                }

                const newRulesObject = {
                    brand:
                        selectingOS == 'IOS'
                            ? undefined
                            : values?.brand?.toLowerCase() ?? undefined,
                    os: selectingOS,
                    osVerFrom: values.osVerFrom != '' ? values.osVerFrom : undefined,
                    osVerTo: values.osVerTo != '' ? values.osVerTo : undefined,
                    sdkVerFrom: values.sdkVerFrom != '' ? values.sdkVerFrom : undefined,
                    sdkVerTo: values.sdkVerTo != '' ? values.sdkVerTo : undefined,
                };

                if (!props.isEdit) {
                    const mData: VenueRuntimeConfigDto = {
                        type: 'RUNTIME',
                        desc: values.desc,
                        content: contentJsonString,
                        enabled: values.enabled,
                        priority: values.priority,
                        rules: newRulesObject,
                    };

                    try {
                        const resp = await createVenueRuntimeConfig(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            setFormLoading(false);
                            return true;
                        }
                    } catch (ex: any) {
                        message.error('error');
                        setFormLoading(false);
                    }
                } else {
                    const mData: VenueRuntimeConfigEditDto = {
                        id: props.processingConfig.id,
                        type: 'RUNTIME',
                        desc: values.desc,
                        content: contentJsonString,
                        enabled: values.enabled,
                        priority: values.priority,
                        rules: newRulesObject,
                    };

                    try {
                        const resp = await updateVenueRuntimeConfig(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            setFormLoading(false);
                            return true;
                        }
                    } catch (ex: any) {
                        message.error('error');
                        setFormLoading(false);
                    }
                }
            }}
        >
            <Flex vertical>
                <Typography.Paragraph>Device Platform</Typography.Paragraph>
                <Space.Compact block>
                    <ProFormSelect
                        fieldProps={{
                            defaultValue: 'ANY',
                            value: selectingOS,
                        }}
                        style={{ width: 120 }}
                        onChange={(value) => {
                            setSelectingOS(value);
                        }}
                        options={[
                            { value: 'ANY', label: 'ANY' },
                            { value: 'IOS', label: 'IOS' },
                            { value: 'AOS', label: 'AOS' },
                        ]}
                    />
                    <ProFormText
                        name="osVerFrom"
                        placeholder={'Minimum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                    <FormItem
                        style={{
                            width: 20,
                            pointerEvents: 'none',
                            textAlign: 'center',
                            backgroundColor: '#f7f7f7',
                        }}
                    >
                        {'-'}
                    </FormItem>
                    <ProFormText
                        name="osVerTo"
                        placeholder={'Maximum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                </Space.Compact>
                {selectingOS == 'IOS' ? undefined : (
                    <ProFormText
                        width="md"
                        label="Device brand"
                        name="brand"
                        placeholder={'(Optional) Enter device brand'}
                    />
                )}
            </Flex>

            <Flex vertical>
                <Typography.Paragraph>SDK version</Typography.Paragraph>
                <Space.Compact block>
                    <ProFormText
                        name="sdkVerFrom"
                        placeholder={'Minimum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                    <FormItem
                        style={{
                            width: 20,
                            pointerEvents: 'none',
                            textAlign: 'center',
                            backgroundColor: '#f7f7f7',
                        }}
                    >
                        {'-'}
                    </FormItem>
                    <ProFormText
                        name="sdkVerTo"
                        placeholder={'Maximum version'}
                        rules={[
                            {
                                validator(_, value) {
                                    return /^\d+(\.\d+)*$/.test(value) ||
                                        value == null ||
                                        value == ''
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Invalid version code'));
                                },
                            },
                        ]}
                    />
                </Space.Compact>
            </Flex>

            <ProFormTextArea
                label="Description"
                name="desc"
                placeholder={'Enter short description'}
                fieldProps={{ rows: 2 }}
                rules={[{ required: true }]}
            />

            <ProFormTextArea
                name="content"
                label={'Config'}
                placeholder="Enter JSON config"
                fieldProps={{ rows: 8 }}
                rules={[
                    { required: true },
                    {
                        validator(_, value) {
                            try {
                                JSON.parse(value);
                                return Promise.resolve();
                            } catch (ex) {
                                return Promise.reject(new Error('Invalid JSON format'));
                            }
                        },
                    },
                ]}
            />

            <ProFormGroup>
                <ProFormDigit
                    label="Priority"
                    name="priority"
                    initialValue={0}
                    placeholder="Enter the priority"
                />
                <ProFormSwitch name="enabled" label="Enable" initialValue={true} />
            </ProFormGroup>
        </ModalForm>
    );
};

export type DeleteVenueRuntimeConfigProp = BaseDialogProps & {
    processingVenueId: string;
    processingConfig: any;
};

export const DeleteVenueRuntimeConfigModule: React.FC<DeleteVenueRuntimeConfigProp> = (props) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const confirmDelete = async () => {
        setConfirmLoading(true);
        try {
            const resp = await deleteVenueRuntimeConfig(
                props.processingVenueId,
                props.processingConfig.id,
            );
            if (resp.response?.status === 200 || resp.response?.status === 201) {
                if (props.onSuccess) props.onSuccess();
            }
        } catch (ex: any) {
            message.error('Error');
        }
        setOpen(false);
        setConfirmLoading(false);
    };

    const cancelDelete = () => {
        setOpen(false);
    };
    return (
        <>
            {props.trigger ? <div onClick={() => setOpen(true)}>{props.trigger}</div> : undefined}
            <Modal
                title={'Delete runtime config'}
                open={open}
                onOk={confirmDelete}
                okButtonProps={{ danger: true }}
                confirmLoading={confirmLoading}
                onCancel={cancelDelete}
            >
                <p>{'Are you sure to delete this runtime config?'}</p>
                <p>{props.processingConfig.desc}</p>
            </Modal>
        </>
    );
};

export type EditVenueBoundConfigProp = BaseDialogProps & {
    venueId: string;
    editingConfig: VenueBoundConfigDto;
};
export const EditVenueBoundConfigModule: React.FC<EditVenueBoundConfigProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [venueBoundConfigForm] = Form.useForm();
    const _handleSubmit = async (values: any) => {
        if (values.radius <= 0) {
            message.error('Radius must be > 0');
            return;
        }

        setFormLoading(true);
        let result = false;

        const finalBeaconList = [];
        if (values.beacons) {
            for (let i = 0; i < values.beacons.length; i++) {
                const bObj = values.beacons[i];
                const idStr = `${bObj.uuid},${bObj.major},${bObj.minor}`;
                finalBeaconList.push({ id: idStr, thres: Number(bObj.thres) });
            }
        }

        const mData: VenueBoundConfigDto = {
            beacons: finalBeaconList,
            radius: values.radius,
            polygon: values.polygon ? JSON.parse(values.polygon) : null,
        };

        try {
            const resp = await updateVenueActiveBoundConfig(props.venueId, mData);
            if (resp.response?.status === 200 || resp.response?.status === 201) {
                if (props.onSuccess) props.onSuccess();
                result = true;
                setFormLoading(false);
                return result;
            } else {
                message.error('error');
                setFormLoading(false);
                return result;
            }
        } catch (ex: any) {
            message.error('error');
            setFormLoading(false);
            return result;
        }
    };

    return (
        <ModalForm
            form={venueBoundConfigForm}
            title={'Edit bound config'}
            trigger={props.trigger}
            modalProps={{ style: { top: 24 } }}
            submitter={{
                searchConfig: {
                    submitText: 'Update',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    venueBoundConfigForm.resetFields();
                    const bObjArr = [];
                    for (const bConf of props.editingConfig.beacons) {
                        const idStr = (bConf.id ?? '').split(',');
                        if (idStr.length === 3) {
                            bObjArr.push({
                                uuid: idStr[0],
                                major: Number(idStr[1]),
                                minor: Number(idStr[2]),
                                thres: Number(bConf.thres),
                            });
                        }
                    }
                    venueBoundConfigForm.setFieldValue('beacons', bObjArr);
                    venueBoundConfigForm.setFieldValue('radius', props.editingConfig.radius);
                    venueBoundConfigForm.setFieldValue(
                        'polygon',
                        JSON.stringify(props.editingConfig.polygon),
                    );
                }
            }}
            onFinish={_handleSubmit}
        >
            <Alert
                message="Warning: The bound configuration may automatically update if the user makes any changes to the floor plan mask or beacon plan. 
            Please proceed with caution as modifying these elements can affect the bound configuration."
                banner
            />
            <ProFormList
                name="beacons"
                label="Beacons"
                className="tiny-row"
                copyIconProps={{
                    tooltipText: 'Duplicate record',
                }}
                deleteIconProps={{
                    tooltipText: 'Remove record',
                }}
                creatorButtonProps={{
                    creatorButtonText: 'Add beacons',
                }}
            >
                <ProFormGroup key="group">
                    <ProFormText name="uuid" label="UUID" width="sm" rules={[{ required: true }]} />
                    <ProFormDigit
                        name="major"
                        label="Major"
                        width="xs"
                        rules={[{ required: true }]}
                    />
                    <ProFormDigit
                        name="minor"
                        label="Minor"
                        width="xs"
                        rules={[{ required: true }]}
                    />
                    <ProFormDigit
                        name="thres"
                        label="Thres"
                        width="xs"
                        rules={[{ required: true }]}
                        max={0}
                        min={-9999}
                    />
                </ProFormGroup>
            </ProFormList>

            <ProFormDigit
                width="md"
                name="radius"
                label="Radius"
                placeholder="Default radius is 100"
                min={0}
                rules={[{ required: true }]}
            />
            <ProFormTextArea
                name="polygon"
                label="Polygon"
                placeholder="Enter the venue boundary polygon. i.e. [[lng, lat], [lng, lat], ...]"
            />
        </ModalForm>
    );
};

export type AddVenuePackageConfigProp = BaseDialogProps & {
    venueId: string;
    processingConfig: any;
    isEdit: boolean;
};
export const AddVenuePackageConfigModule: React.FC<AddVenuePackageConfigProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [venuePackageConfigForm] = Form.useForm();

    return (
        <ModalForm
            form={venuePackageConfigForm}
            title={'Package config'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: props.isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            modalProps={{
                onCancel: () => {
                    venuePackageConfigForm.resetFields();
                },
            }}
            onOpenChange={(isOpen) => {
                if (props.processingConfig) {
                    venuePackageConfigForm.setFieldsValue({
                        enabled: props.processingConfig.enabled,
                        desc: props.processingConfig.desc,
                        content: JSON.stringify(
                            JSON.parse(props.processingConfig.content),
                            null,
                            3,
                        ),
                    });
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);

                let contentJsonString = '';
                try {
                    contentJsonString = JSON.stringify(JSON.parse(values.content));
                } catch (error) {
                    message.error('Content json format invalid');
                    setFormLoading(false);
                    return;
                }

                if (!props.isEdit) {
                    const mData: VenueRuntimeConfigDto = {
                        type: 'PACKAGE',
                        enabled: values.enabled ?? false,
                        desc: values.desc,
                        content: contentJsonString,
                        priority: 1,
                        rules: {},
                    };
                    try {
                        const resp = await createVenueRuntimeConfig(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            setFormLoading(false);
                            return true;
                        }
                    } catch (ex: any) {
                        message.error('error');
                        setFormLoading(false);
                    }
                } else {
                    const mData: VenueRuntimeConfigEditDto = {
                        id: props.processingConfig.id,
                        type: 'PACKAGE',
                        enabled: values.enabled ?? false,
                        desc: values.desc,
                        content: contentJsonString,
                        priority: 1,
                        rules: {},
                    };
                    try {
                        const resp = await updateVenueRuntimeConfig(props.venueId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            setFormLoading(false);
                            return true;
                        }
                    } catch (ex: any) {
                        message.error('error');
                        setFormLoading(false);
                    }
                }
            }}
        >
            <ProFormSwitch name="enabled" label="Enabled" />

            <ProFormText
                label="Short description"
                name="desc"
                placeholder={'Enter a short description'}
                rules={[{ required: true }]}
            />

            <ProFormTextArea
                name="content"
                label={'Content'}
                placeholder="Enter the config JSON"
                fieldProps={{ rows: 8 }}
                rules={[
                    { required: true },
                    {
                        validator(_, value) {
                            try {
                                JSON.parse(value);
                                return Promise.resolve();
                            } catch (ex) {
                                return Promise.reject(new Error('Invalid JSON format'));
                            }
                        },
                    },
                ]}
            />
        </ModalForm>
    );
};
