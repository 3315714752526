import {
    ModalForm,
    ProFormText,
    ProFormSwitch,
    ProFormDatePicker,
    ProFormList,
} from '@ant-design/pro-components';
import { Descriptions, Form } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import {
    LicenseConfigDto,
    updateLicenseConfigDto,
    createLicense,
    updateLicense,
    LicenseDto,
} from 'apis/ProjectApi';

import { BaseDialogProps } from './BaseDialogProps.type';

export type AddProjLicenseProp = BaseDialogProps & {
    projectId: string;
    record?: LicenseDto;
};

const AddProjLicenseModule: React.FC<AddProjLicenseProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [licenseForm] = Form.useForm();
    const isEdit = useMemo(() => props.record?.accessKey !== undefined, [props.record]);
    const mWhitelist = useMemo(() => {
        return (props?.record?.whitelist ?? []).map((el) => ({ value: el }));
    }, [props.record]);
    useEffect(() => {
        licenseForm.setFieldsValue({
            expiredAt: props.record?.expiredAt,
            whitelist: isEdit ? mWhitelist : [{ value: '*' }],
            enabled: props.record?.enabled ?? true,
        });
    }, [props.record]);

    return (
        <ModalForm
            form={licenseForm}
            title={isEdit ? 'Edit license ' : 'Add license'}
            trigger={props.trigger}
            modalProps={{ style: { top: 24 } }}
            initialValues={{
                expiredAt: props.record?.expiredAt,
                whitelist: isEdit ? mWhitelist : [{ value: '*' }],
                enabled: props.record?.enabled,
            }}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    licenseForm.resetFields();
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);

                let finalExpiredAt = new Date(values.expiredAt);
                let finalWhiteList = [];
                for (
                    let whitelistLoop = 0;
                    whitelistLoop < values.whitelist.length;
                    whitelistLoop++
                ) {
                    finalWhiteList.push(values.whitelist[whitelistLoop].value);
                }

                let finalEnabled = values.enabled ? true : false;

                let result = false;
                if (!isEdit) {
                    const mData: LicenseConfigDto = {
                        expiredAt: finalExpiredAt,
                        enabled: finalEnabled,
                        whitelist: finalWhiteList,
                    };

                    try {
                        const resp = await createLicense(props.projectId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                        }
                    } catch (ex: any) {}
                } else {
                    const mData: updateLicenseConfigDto = {
                        expiredAt: finalExpiredAt,
                        enabled: values.enabled,
                        whitelist: finalWhiteList,
                        accessKey: props.record!.accessKey,
                    };
                    try {
                        const resp = await updateLicense(props.projectId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                        }
                    } catch (ex: any) {}
                }

                setFormLoading(false);
                return result;
            }}
        >
            {isEdit ? (
                <Descriptions column={1} layout="vertical" style={{ marginBottom: 12 }}>
                    <Descriptions.Item label="Access key">
                        {props.record!.accessKey}
                    </Descriptions.Item>
                    <Descriptions.Item label="Access secret">
                        {props.record!.accessSecret}
                    </Descriptions.Item>
                </Descriptions>
            ) : undefined}

            <ProFormDatePicker
                colProps={{ xl: 8, md: 12 }}
                label="Expiry date"
                name="expiredAt"
                rules={[{ required: true }]}
                width="lg"
            />

            <ProFormList
                name="whitelist"
                label="Whitelisted App ID"
                className="tiny-row"
                deleteIconProps={{ tooltipText: 'Remove record' }}
                creatorButtonProps={{ creatorButtonText: 'Add App ID' }}
            >
                <ProFormText name="value" width="lg" placeholder="Please enter whitelist App ID" />
            </ProFormList>

            <ProFormSwitch name="enabled" colProps={{ span: 4 }} label="Enabled" />
        </ModalForm>
    );
};
export default React.memo(AddProjLicenseModule);
