import { ProCard, ProList } from '@ant-design/pro-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, message, Spin, Typography, Flex, Input } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useLocation } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import CustomIcon from 'components/CustomIcon';
import { useOutdoorState } from 'providers/OutdoorProvider';
import { LandmarkDto } from 'apis/LandmarkApi';
import { BasicListTier } from 'components/BasicTier';
import LandmarkDetailSection from './LandmarkDetailSection';
import MapBoxContainer from 'components/maps/MapBoxContainer';
import MapBoxMarkerLayer from 'components/maps/MapBoxMarkerLayer';
import LandmarkInputModule from 'components/dialogs/LandmarkInputDialog';

const LandmarkScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const {
        isLoading,
        venue,
        workingLandmark,
        selectLandmark,
        landmarks,
        lmPageConf,
        fetchLandmarks,
    } = useOutdoorState();
    const { activeProject, project, isMobile, isTablet } = useAppState();
    const { state } = useLocation();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const [keyword, setKeyword] = useState<string | undefined>();
    const drawRef = useRef<any>();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (!!state?.lmId && !!landmarks) {
            selectLandmark(landmarks.find((el) => el.id === state.lmId));
        }
    }, []);

    useEffect(() => {
        if (drawRef.current?.resetAll) {
            const entity = !landmarks
                ? undefined
                : landmarks.map((el, idx) => {
                      return {
                          id: idx,
                          strId: el.id,
                          label: el.name,
                          lat: el.position.lat,
                          lng: el.position.lng,
                      };
                  });
            drawRef.current.resetAll(entity);
        }
    }, [landmarks]);

    useEffect(() => {
        if (workingLandmark?.id) {
            scrollToId(workingLandmark.id);
            const idx = landmarks?.findIndex((el) => el.id === workingLandmark.id);
            if (drawRef.current) {
                drawRef.current.flyTo(idx);
                drawRef.current.selectById(idx);
            }
        }
    }, [workingLandmark]);

    const scrollToId = (id: string) => {
        const element = document.querySelector(`[data-lm-id="${id}"]`);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
    };

    const filteredLms = useMemo(() => {
        if (!keyword) return landmarks ?? [];
        return (landmarks ?? []).filter((el) => {
            return (
                el.name.toLowerCase().includes(keyword) ||
                (el.address ?? '').includes(keyword) ||
                el.id.toLowerCase().includes(keyword)
            );
        });
    }, [landmarks, keyword]);

    function _renderLandmarkList() {
        return (
            <ProList<LandmarkDto>
                loading={isLoading}
                rowKey="id"
                size="small"
                className="tiny-row"
                style={{ flex: 1, overflowY: 'auto', paddingBottom: 16 }}
                itemLayout={isTablet ? 'vertical' : undefined}
                dataSource={filteredLms}
                renderItem={(item, index) => {
                    const svCount = item.survey ?? 0;
                    let baseCls = 'w100 cursor-pointer';
                    if (item.id === workingLandmark?.id) {
                        baseCls += ` selected-row`;
                    }
                    return (
                        <Flex
                            data-lm-id={item.id}
                            className={baseCls}
                            gap={6}
                            style={{ padding: '4px 8px 4px 12px', borderBottom: '1px #eee solid' }}
                            onClick={(evt) => selectLandmark(item)}
                        >
                            <BasicListTier
                                style={{ flex: 1 }}
                                title={item.name}
                                description={
                                    <Typography.Text type="secondary" className="text-08" ellipsis>
                                        {item.address ?? item.id}
                                    </Typography.Text>
                                }
                            />
                            <CustomIcon
                                icon={svCount > 0 ? 'survey' : 'survey_err'}
                                color={svCount > 0 ? 'green' : 'red'}
                                size={18}
                                title="Surveyed"
                            />
                        </Flex>
                    );
                }}
                pagination={{
                    size: 'small',
                    defaultCurrent: (lmPageConf.current?.page ?? 0) + 1,
                    defaultPageSize: lmPageConf.current?.limit ?? 20,
                    total: lmPageConf.current?.total ?? 1,
                    current: (lmPageConf.current?.page ?? 0) + 1,
                    pageSizeOptions: [10, 20, 50, 100, 1000],
                    showTotal: () => undefined,
                    onChange: (page, size) => {
                        fetchLandmarks({ limit: size, page: page - 1 });
                    },
                }}
            />
        );
    }

    function _renderMapInfo() {
        if (!workingLandmark) return undefined;
        return (
            <div style={{ position: 'absolute', padding: 16, zIndex: 500 }} className="w100">
                {/* Working landmark: {workingLandmark.name} */}
            </div>
        );
    }

    const _renderMapSection = () => {
        return (
            <div className="w100 h100" style={{ backgroundColor: '#eee' }}>
                <MapBoxContainer>
                    <MapBoxMarkerLayer
                        ref={drawRef as any}
                        editable={false}
                        onClick={({ id, strId }) => {
                            scrollToId(strId ?? (id as string));
                        }}
                        labelVisible={false}
                    />
                </MapBoxContainer>
            </div>
        );
    };

    if (!project || !venue) return <Spin />;
    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            {contextHolder}
            <ProCard
                colSpan={isMobile ? undefined : '30%'}
                className="venue-panel"
                bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            >
                <Flex justify="space-between" gap={6} style={{ margin: 12 }}>
                    <Input
                        key="map-search-box"
                        size="small"
                        allowClear
                        variant="filled"
                        suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,0.3)' }} />}
                        onChange={(e) => {
                            setKeyword(e.target?.value?.toLowerCase());
                        }}
                        placeholder="Search landmarks"
                    />
                    {isEditor ? (
                        <LandmarkInputModule
                            key="btn-add-venueMap"
                            venueId={venue!.id}
                            record={undefined}
                            trigger={
                                <Button size="small" type="dashed" icon={<PlusOutlined />}></Button>
                            }
                            onSuccess={(result) => {
                                fetchLandmarks();
                                if (result?.id) selectLandmark(result.id);
                                messageApi.success('Landmark added');
                            }}
                        />
                    ) : undefined}
                </Flex>
                {keyword && !!landmarks ? (
                    <center>
                        <Typography.Text type="secondary">
                            {filteredLms.length} / {landmarks.length} map matches
                        </Typography.Text>
                    </center>
                ) : undefined}

                {_renderLandmarkList()}
            </ProCard>
            <LandmarkDetailSection />
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#ddd',
                    position: 'relative',
                }}
            >
                {_renderMapInfo()}
                {_renderMapSection()}
            </div>
        </ProCard>
    );
};

export default LandmarkScreen;
