import ApiClient from 'utils/NetworkManager';
import { AlignmentDto } from './VenueApi';

const statsBaseUrl = process.env.REACT_APP_STATS_API_BASE_URL;

export type TraceExecutionStatus = 'CANCELLED' | 'FAILED' | 'QUEUED' | 'RUNNING' | 'SUCCEEDED'

export const TRACE_EXECUTION_PER_PAGE = 20;

export interface PaginationDto {
    total: number;
    totalPage: number;
}

export interface PaginationQueryDto {
    limit: number;
    page?: number;
    offset?: number;
}

export interface SessionEnquiryDto {
    limit?: number;
    page?: number;
    traceId?: string;
    appId?: string;
    deviceId?: string;
    platform?: string;
    from?: number;
    to?: number;
    venueId?: string;
    validated?: boolean;
}

export interface SessionInfoDto {
    traceId: string;
    appId: string;
    deviceId: string;
    platform: string;
    date?: Date;
    duration?: number;
    fileSize?: number;
    integrity: boolean;
    venues?: string[];
}

export interface SessionDetailDto extends SessionInfoDto {
    reqId?: string;
    projectId?: string;
    projectName?: string;
}

export interface TraceDetail {
    sdkId: string;
    sdkVersion: string;
    appId: string;
    appVersion: string;
    dModel: string;
    dId: string;
    dOsType: string;
    dOsVersion: string;
    timeZone: string;
    startAt: number;
    endAt: number;
    duration: number;
    fileName: string;
    fileSize: number;
}

export interface RouteDataDto {
    acc: number;
    alt: number;
    bea: number;
    lat: number;
    lng: number;
    fid?: string;
}

export interface RouteDto {
    name: string;
    extId: string;
    data: Partial<RouteDataDto>[];
    ts: number[];
    isMock: boolean;
    _coordinates?: [number, number][];
    _color?: string;
}

export interface MarkerDto {
    ts: number;
    name: string;
    data: [number, number];
    extId?: string;
}

export interface HistoryDto {
    category: string;
    extId?: string;
    extra?: any;
    msg?: string;
    ts: number;
}

export interface SampleDto {
    name: string;
    data: any[];
    _color?: string;
}

export interface VenuePreviewDto {
    id: string;
    level: number;
    venueName: string;
    name: string;
    imageUrl?: string;
    mapAlign?: AlignmentDto;
}

export interface TraceSession {
    traceId: string;
    reqId: string;
    detail: TraceDetail;
    route: RouteDto[];
    marker: MarkerDto[];
    history: HistoryDto[];
    venue: VenuePreviewDto[];
    sample: SampleDto[];
    rtConfig: [string, string | number | boolean][];
    dlUrl: string;
}

export interface CrashEnquiryDto {
    limit?: number;
    page?: number;
    appId: string;
    from?: number;
    to?: number;
}

export interface CrashRecordDto {
    id: string;
    appId: string;
    date?: Date;
    detail?: string;
}

export interface TraceExecution {
    executionId: string;
    expiredAt: Date;
    createdAt: Date;
    queryInBase64: string;
    status: TraceExecutionStatus;
    reason?: string;
}

export interface TraceExecutionResult {
    nextToken: string;
    results: Record<string, any>[];
}

export interface TraceExecutionResultPaging {
    limit?: number;
    nextToken?: string;
}

export interface TraceExecutionSelected {
    id: string | null,
    query: string | null,
    loaded: boolean,
}

export const searchSession = (filter: SessionEnquiryDto) =>
    ApiClient.get<SessionInfoDto[]>(`/session/list`, filter);
export const getTraceDetail = (traceId: string) =>
    ApiClient.get<SessionDetailDto>(`/session/${traceId}`);
export const traceSession = (traceId: string) =>
    ApiClient.get<TraceSession>(`${statsBaseUrl}/analysis/trace?id=${traceId}`);
export const getTraceFileUrl = (fileName: string) =>
    `${statsBaseUrl}/analysis/trace/download?trace_file=${fileName}`;
export const searchCrashLog = (filter: CrashEnquiryDto) =>
    ApiClient.get<CrashRecordDto[]>(`/session/crash`, filter);
export const getCrashDlUrl = (crashId: string) =>
    ApiClient.post<{ dlUrl: string }>(`/session/crash/dl`, { id: crashId });
export const traceAdvanceSearch = (query: string) =>
    ApiClient.post<{ executionId: string }>(`/trace/advance/search`, { query });
export const traceExecutionStatus = (id: string) =>
    ApiClient.get<{ status: TraceExecutionStatus }>(`/trace/advance/${id}/status`);
export const traceExecutionCount = (id: string) =>
    ApiClient.get<{ count: number }>(`/trace/advance/${id}/count`);
export const traceExecutionResults = (id: string, paging?: TraceExecutionResultPaging) =>
    ApiClient.get<TraceExecutionResult>(`/trace/advance/${id}/results`, paging);
export const traceExecutions = (paging: PaginationQueryDto) =>
    ApiClient.get<TraceExecution[]>(`/trace/advance/executions`, paging);

