import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, CaretDownOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ResourceRole } from 'apis/UserApi';
import VenueContextProvider, { useVenueState } from 'providers/VenueProvider';
import { Button, Dropdown, Flex, Result, Space, Tabs, Typography, theme } from 'antd';

const basePathRegex = '^/project/venue/([0-9a-fA-F\\-]*)';
const tabConfig = [
    { label: 'Overview', key: 'overview', childPath: null },
    { label: 'Map', key: 'map', childPath: 'map' },
    { label: 'Alignment', key: 'align', childPath: 'map/align', match: true, editor: true },
    { label: 'Mask', key: 'mask', childPath: 'mask' },
    { label: 'Survey', key: 'survey', childPath: 'survey' },
    { label: 'Augmentation', key: 'augment', childPath: 'augment', match: true, su: true },
    { label: 'Beacon Plan', key: 'beacon', childPath: 'beacon' },
    { label: 'Site Package', key: 'sitePackage', childPath: 'package', editor: true },
    { label: 'Activity Log', key: 'activity', childPath: 'activity', editor: true },
    { label: 'Config', key: 'config', childPath: 'config', su: true },
];

const VenueScreenLayout: React.FC = () => {
    const token = theme.useToken();
    const { isSuperAdmin, hasAccess } = useAuth();
    const { activeProject, project, isMobile, setNavCollapsed } = useAppState();
    const { activeVenueId, venue, switchVenue } = useVenueState();
    const [activeTab, setActiveTab] = useState<string>('overview');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!activeProject) {
            // Check user has access
            const isViewer = hasAccess(ResourceRole.VIEWER, activeProject?.projId);
            if (!activeProject || !isViewer) {
                navigate('/home', { replace: true });
            }
            // Check venue exist in current project.
            if (project?.venues && location?.state?.venueId) {
                const hasVenue = project.venues.find((el) => el?.id === location.state.venueId);
                if (!hasVenue) {
                    navigate('/home', { replace: true });
                }
            }
        }
    }, [activeProject]);

    useEffect(() => {
        const matched = tabConfig.find((el) => {
            const mRegex = el.childPath
                ? new RegExp(basePathRegex + '/' + el.childPath + '$')
                : new RegExp(basePathRegex + '$');
            return !!location.pathname.match(mRegex);
        });
        if (matched) {
            const targetMapId = location.search
                ? new URLSearchParams(location.search).get('mapId')
                : undefined;
            setActiveTab(matched.key);
            if ((matched?.childPath ?? '').length > 0) setNavCollapsed(true);
            if (!activeVenueId) {
                const match = new RegExp(basePathRegex + '/?.*').exec(location.pathname);
                if (match && match.length > 1) {
                    const id = match[1];
                    switchVenue(id, targetMapId);
                }
            }
        }
    }, [location.pathname]);

    function handleTabChange(key: string, mapId: string | undefined = activeVenueId) {
        const matched = tabConfig.find((el) => el.key === key);
        if (matched && !!mapId) {
            const childPath = matched.childPath ? `/${matched.childPath}` : '';
            navigate(`/project/venue/${mapId}${childPath}`);
            if (childPath.length > 0) setNavCollapsed(true);
        }
    }

    const matchedTab = useMemo(() => tabConfig.find((el) => el.key === activeTab), [activeTab]);
    const isListScreen = location.pathname === '/project/venue';
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const mTabList = useMemo(() => {
        return isListScreen
            ? undefined
            : tabConfig
                  .filter((el) => {
                      if (el.match === true) {
                          return location.pathname.endsWith(el.childPath);
                      } else if (el.su) {
                          return isSuperAdmin;
                      } else if (el.editor) {
                          return isEditor;
                      } else {
                          return true;
                      }
                  })
                  .map((el) => {
                      return { key: el.key, label: el.label };
                  });
    }, [isListScreen, location.pathname, isSuperAdmin]);

    const _renderVenueHeader = useCallback(() => {
        const allowSwitchVenue = matchedTab?.match !== true;
        return (
            <Space size="large">
                <Button
                    style={{ padding: 0 }}
                    type="link"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => navigate('/project/venue')}
                >
                    All venues
                </Button>
                <Dropdown
                    menu={{
                        items: (project?.venues ?? [])
                            .filter((x) => x.indoor !== false)
                            .map((el) => {
                                return { label: el.name, key: el.id };
                            }),
                        onClick: (info) => {
                            switchVenue(info.key);
                            handleTabChange(activeTab, info.key);
                        },
                    }}
                    trigger={['click']}
                    disabled={!allowSwitchVenue}
                >
                    <Space
                        style={{
                            backgroundColor: '#eee',
                            padding: '2px 16px',
                            cursor: 'pointer',
                            borderRadius: 5,
                        }}
                        size="large"
                        title={venue?.id}
                    >
                        <Typography.Title level={isMobile ? 5 : 4} style={{ margin: 0 }}>
                            {venue?.name}
                        </Typography.Title>
                        {allowSwitchVenue ? <CaretDownOutlined /> : undefined}
                    </Space>
                </Dropdown>
            </Space>
        );
    }, [activeTab, matchedTab, venue, isMobile]);

    return (
        <Flex vertical className="full-content-height v-wrapper">
            <PageHeader
                title={undefined}
                style={{
                    backgroundColor: isListScreen ? undefined : token.token.colorBgBase,
                    padding: '8px 24px 0px 24px',
                }}
                breadcrumb={{ items: [{ title: 'Project' }, { title: 'Venue' }] }}
                breadcrumbRender={isListScreen ? undefined : _renderVenueHeader}
            >
                {!isListScreen ? (
                    <Tabs
                        tabBarStyle={{ marginBottom: 0 }}
                        size="small"
                        activeKey={activeTab}
                        items={mTabList as any}
                        onChange={handleTabChange}
                    />
                ) : undefined}
            </PageHeader>
            <div style={{ flex: 1, overflow: 'auto' }}>
                {matchedTab?.editor && !isEditor ? (
                    <Result status="warning" title="Permission denied" />
                ) : (
                    <Outlet />
                )}
            </div>
        </Flex>
    );
};

const VenueScreenWrapper: React.FC = () => (
    <VenueContextProvider>
        <VenueScreenLayout />
    </VenueContextProvider>
);
export default VenueScreenWrapper;
