import React, { ReactNode } from 'react';
import { Col, Flex, Row, Space, Typography } from 'antd';
import Markdown from 'react-markdown';
const { Text } = Typography;

export interface BasicListTierProps {
    avatar?: ReactNode;
    children?: ReactNode;
    description?: string | ReactNode;
    title?: string | ReactNode;
    style?: React.CSSProperties;
    markdown?: boolean;
    className?: string;
}

export const BasicListTier: React.FC<BasicListTierProps> = (props) => {
    function _getTitle() {
        if (typeof props.title === 'string') {
            return <Text strong>{props.title}</Text>;
        } else {
            return props.title;
        }
    }

    function _getDescription() {
        if (typeof props.description === 'string') {
            return (
                <Text type="secondary">
                    {props.markdown === true ? (
                        <Markdown className="p-no-margin">{props.description}</Markdown>
                    ) : (
                        props.description
                    )}
                </Text>
            );
        } else {
            return props.description;
        }
    }
    return (
        <Row style={props.style} className={props.className} wrap={false}>
            {props?.avatar ? <Col style={{ marginRight: '1em' }}>{props.avatar}</Col> : undefined}
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                {_getTitle()}
                {_getDescription()}
            </Col>
            {props?.children ? (
                <Col style={{ marginLeft: '1em' }}>{props.children}</Col>
            ) : undefined}
        </Row>
    );
};
