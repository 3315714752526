import { ProCard } from '@ant-design/pro-components';
import { Table, Tag, Tooltip, Typography } from 'antd';
import { TableProps } from 'antd/lib';
import { TraceExecution, TraceExecutionStatus } from 'apis/TraceApi';
import moment from 'moment/moment';
import { useTraceContext } from 'providers/TraceProvider';

const executionStatusColor: Record<TraceExecutionStatus, string> = {
    CANCELLED: 'brown',
    FAILED: 'error',
    QUEUED: 'purple',
    RUNNING: 'blue',
    SUCCEEDED: 'green',
};

const TraceQueryExecutionsCard = ({ isMobile, onView, onPageChanged }: {
    isMobile: boolean,
    onView: (id: string, query: string) => void,
    onPageChanged: (page: number, size: number) => void,
}) => {
    const {
        executionList, executionPageConfig,
    } = useTraceContext();

    const executionColumns: TableProps<TraceExecution>['columns'] = [
        {
            title: 'ID',
            dataIndex: 'executionId',
            key: 'executionId',
            width: 100,
            render: (field) => <Tooltip color="lightgrey"
                                        title={<Typography.Text copyable>{field}</Typography.Text>}
                                        placement="top">
                {`${field.substring(0, 8)}...`}
            </Tooltip>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (field: TraceExecutionStatus, entity: TraceExecution) => <Tooltip
                title={entity.reason}>
                <Tag color={executionStatusColor[field]}>{field}</Tag>
            </Tooltip>,
        },
        {
            title: 'Query',
            dataIndex: 'queryInBase64',
            key: 'queryInBase64',
            render: (field: string) => atob(field),
        },
        {
            title: 'Created Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 170,
            render: (field: Date) => moment(field).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (_, record) => (
                record.status === 'SUCCEEDED' ?
                    <a onClick={() => onView(record.executionId, atob(record.queryInBase64))}>View Results</a> : null
            ),
        },
    ];

    return <ProCard title={`Executions (${executionPageConfig?.total ?? 0})`} style={{ marginTop: '1em' }}
                    ghost={isMobile}>
        <Table
            rowKey="executionId"
            columns={executionColumns}
            dataSource={executionList}
            scroll={{ x: true }}
            pagination={{
                defaultCurrent: 1,
                defaultPageSize: executionPageConfig.limit,
                total: executionPageConfig?.total ?? 1,
                current: (executionPageConfig?.page ?? 0) + 1,
                showTotal: (total) => `Total ${total} items`,
                onChange: onPageChanged,
            }}
            size="small"
        />
    </ProCard>;
};

export default TraceQueryExecutionsCard;
