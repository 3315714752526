import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import LoginScreen from 'pages/LoginScreen';
import NoFoundPage from 'pages/NotFound';
import { ScreenLayout } from 'components/ScreenLayout';
import { PageContainer, ProCard } from '@ant-design/pro-components';
import { Spin } from 'antd';
import HomeScreen from 'pages/HomeScreen';
import ProjectScreen from 'pages/ProjectScreen';
import UserListScreen from 'pages/UserListScreen';
import PermissionScreen from 'pages/PermissionScreen';
import UserInputScreen from 'pages/UserInputScreen';
import LicenseListScreen from 'pages/LicenseListScreen';
import { ResourceRole } from 'apis/UserApi';
import TraceSearchScreen from 'pages/TraceSearchScreen';
import TraceDetailScreen from 'pages/TraceDetailScreen';
import AlignmentScreen from 'pages/venue/AlignmentScreen';
import VenueScreenWrapper from 'pages/venue/VenueScreenWrapper';
import VenueListScreen from 'pages/venue/VenueListScreen';
import VenueOverviewScreen from 'pages/venue/VenueOverviewScreen';
import VenueMapScreen from 'pages/venue/VenueMapScreen';
import VenuePackageScreen from 'pages/venue/VenuePackageScreen';
import VenueConfigScreen from 'pages/venue/VenueConfigScreen';
import VenueBeaconPlannerScreen from 'pages/venue/VenueBeaconPlannerScreen';
import ActivityLogScreen from 'pages/venue/ActivityLogScreen';
import TraceContextProvider from 'providers/TraceProvider';
import VenueFloorplanMaskScreen from 'pages/venue/VenueFloorplanMaskScreen';
import LicenseConfigScreen from 'pages/LicenseConfigScreen';
import VenueSurveyScreen from 'pages/venue/VenueSurveyScreen';
import CrashSearchScreen from 'pages/CrashSearchScreen';
import CrowdSourceScreen from 'pages/CrowdsourceScreen';
import AugmentAlignScreen from 'pages/venue/AugmentAlignScreen';
import OutdoorScreenWrapper from 'pages/outdoor/OutdoorScreenWrapper';
import OutdoorOverviewScreen from 'pages/outdoor/OutdoorOverviewScreen';
import LandmarkScreen from 'pages/outdoor/LandmarkScreen';

type DummyProps = {
    title?: string;
    subTitle?: string;
};

const DummyScreen: React.FC<DummyProps> = (props) => {
    return (
        <PageContainer title={props.title ?? 'Title'} subTitle={props.subTitle ?? 'Sub title'}>
            <ProCard style={{ height: '10vh', minHeight: 100 }}>
                <div />
            </ProCard>
        </PageContainer>
    );
};

const AppRouter: React.FC = () => {
    const { isAuthorized, user, isReady, isSuperAdmin, hasAccess } = useAuth();
    if (!isReady) {
        return <Spin />;
    }
    // console.log('isAuthorized:', isAuthorized, '; user:', user);
    return (
        <Routes>
            <Route
                path="/login"
                element={isAuthorized ? <Navigate to="/" replace /> : <LoginScreen />}
            />
            <Route path="/" element={<ScreenLayout />}>
                <Route index element={<HomeScreen />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/auth/users" element={<UserListScreen />} />
                {isSuperAdmin || hasAccess(ResourceRole.ADMIN) ? (
                    <Route path="/auth/user/create" element={<UserInputScreen mode="CREATE" />} />
                ) : undefined}
                <Route path="/project" element={<ProjectScreen />} />
                <Route path="/project/permission" element={<PermissionScreen />} />
                <Route path="/project/license" element={<LicenseListScreen />} />
                <Route path="/project/license/config" element={<LicenseConfigScreen />} />
                <Route path="/project/venue" element={<VenueScreenWrapper />}>
                    <Route index element={<VenueListScreen />} />
                    <Route path="/project/venue/:venueId" element={<VenueOverviewScreen />} />
                    <Route path="/project/venue/:venueId/map" element={<VenueMapScreen />} />
                    <Route
                        path="/project/venue/:venueId/mask"
                        element={<VenueFloorplanMaskScreen />}
                    />
                    <Route path="/project/venue/:venueId/map/align" element={<AlignmentScreen />} />
                    <Route path="/project/venue/:venueId/survey" element={<VenueSurveyScreen />} />
                    <Route
                        path="/project/venue/:venueId/package"
                        element={<VenuePackageScreen />}
                    />
                    <Route
                        path="/project/venue/:venueId/activity"
                        element={<ActivityLogScreen />}
                    />
                    {isSuperAdmin ? (
                        <Route
                            path="/project/venue/:venueId/config"
                            element={<VenueConfigScreen />}
                        />
                    ) : undefined}
                    <Route
                        path="/project/venue/:venueId/beacon"
                        element={<VenueBeaconPlannerScreen />}
                    />
                    {isSuperAdmin ? (
                        <Route
                            path="/project/venue/:venueId/augment"
                            element={<AugmentAlignScreen />}
                        />
                    ) : undefined}
                </Route>
                <Route path="/project/outdoor" element={<OutdoorScreenWrapper />}>
                    <Route index element={ <Navigate to="/project/venue" replace /> } />
                    <Route path="/project/outdoor/:venueId" element={<OutdoorOverviewScreen />} />
                    <Route path="/project/outdoor/:venueId/landmark" element={<LandmarkScreen />} />
                    <Route
                        path="/project/outdoor/:venueId/package"
                        element={<VenuePackageScreen />}
                    />
                    <Route
                        path="/project/outdoor/:venueId/activity"
                        element={<ActivityLogScreen />}
                    />
                    {isSuperAdmin ? (
                        <Route
                            path="/project/outdoor/:venueId/config"
                            element={<VenueConfigScreen />}
                        />
                    ) : undefined}
                </Route>
                <Route path="/analysis/session" element={<TraceContextProvider />}>
                    <Route index element={<TraceSearchScreen />} />
                    <Route path="/analysis/session/:traceId" element={<TraceDetailScreen />} />
                </Route>
                {isSuperAdmin ? (
                    <Route path="/analysis/crash" element={<CrashSearchScreen />} />
                ) : undefined}
                {isSuperAdmin ? (
                    <Route path="/crowd-source" element={<CrowdSourceScreen />} />
                ) : undefined}
            </Route>
            <Route
                path="*"
                element={isAuthorized ? <NoFoundPage /> : <Navigate to="/login" replace />}
            />
        </Routes>
    );
};

export default AppRouter;
